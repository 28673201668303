import { Component, EventEmitter, Input } from "@angular/core";
import { DataService } from "src/app/services/chat/data.service";
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from "@angular/router";
import { ModalService } from "angular-5-popup";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { UpdateFinderInformation } from "src/app/models/chatbotmessage";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { User } from "@sentry/browser";
import { NgbdPopupmodelDocContent } from "../popupmodeldoc/popupmodeldoc.component";
import { TranslateService } from "@ngx-translate/core";
class data {
  showMe;
  loading;
  chatvideo ;
  urlSafe;
}
class Videocall{
  meeting_url ;
}
@Component({
  selector: 'contact-info-dialog',
  styleUrls: ['./contact-info-dialog.scss'],
  templateUrl: './contact-info-dialog.html',

})
export class ContactInfoDialog{
  @Input() public chatinfo:boolean;
  @Input() public srcimage:any;
  @Input() public test:any;
  @Input() public texte:string;
  @Input() public color:any;
  @Input() public title:string;
  @Input() public type:string;
  @Input() public number = 0;
  public event: EventEmitter<any> = new EventEmitter();
  @Input() public buttonText = 'editprofil_label_clickhere';
  contactInfo: FormGroup;
  currentUser : User;
  loading: boolean = false;


  constructor(public dataservice :DataService,public sanitizer: DomSanitizer, private router: Router, private modalService: NgbModal,
    private _formBuilder: FormBuilder, private translateService: TranslateService) {
  }
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.contactInfo = this._formBuilder.group({
      email: new FormControl('',[
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      phone: new FormControl(''),
      note: new FormControl(''),
    })
  }

  navigate() {
   this.loading = true;
    let updateFinderInformation: UpdateFinderInformation = new UpdateFinderInformation();
    updateFinderInformation.email = this.contactInfo.value.email;
    updateFinderInformation.first_name = this.contactInfo.value.firstName;
    updateFinderInformation.last_name = this.contactInfo.value.lastName;
    updateFinderInformation.phone = this.contactInfo.value.phone;
    updateFinderInformation.note = this.contactInfo.value.note;
    updateFinderInformation.reward_request = 1;

    this.dataservice.updateUser(this.currentUser?.finder_info?.id, updateFinderInformation).pipe()
      .subscribe((data)=>{
        this.dataservice.sendFinderInfoEmail(this.currentUser?.finder_info?.id)
        .subscribe((data)=>{
          this.openSuccess(updateFinderInformation)
          this.loading = false;
        },()=>{
          this.loading = false;
        this.openError('Error when submit the form')
        this.event.emit("");
        });

      },error=>{
        this.openError('Error when submit the form')
        this.loading = false;
        this.event.emit("");

      });
    }

    openError(content) {
      let options: NgbModalOptions = {
        ariaLabelledBy: 'myBasicModalLabel',
        size: 'sm',
        centered :true,
        animation :true,
        backdrop: true,
        keyboard: true,
       windowClass : "myCustomModalClass",

      };

      const modeldoc: any =this.modalService.open(NgbdPopupmodelDocContent,options);
      modeldoc.componentInstance.chatinfo = true;
      modeldoc.componentInstance.number = 1;
      modeldoc.componentInstance.title =content;
      modeldoc.componentInstance.texte="finder_response_titleerror";
      modeldoc.componentInstance.color="#F4C809";
      modeldoc.componentInstance.type="error";
      modeldoc.componentInstance.event.subscribe((data:any) => {

        modeldoc.close();
        this.router.navigate(['']);
      });
    }

    openSuccess(finderInfo: UpdateFinderInformation) {
      let options: NgbModalOptions = {
        ariaLabelledBy: 'myBasicModalLabel',
        size: 'sm',
        centered :true,
        animation :true,
        backdrop: true,
        keyboard: true,
       windowClass : "myCustomModalClass",

      };

      const modeldoc =this.modalService.open(NgbdPopupmodelDocContent,options);
      modeldoc.componentInstance.chatinfo = true;
      modeldoc.componentInstance.number = 4;
      modeldoc.componentInstance.title ='chatbot_script_medical_thnktext1';
      modeldoc.componentInstance.texte = 'details_sent';
      modeldoc.componentInstance.buttonText= "OK"
      modeldoc.componentInstance.color="#04a6bc";
      modeldoc.componentInstance.type="error";
      modeldoc.componentInstance.event.subscribe((data:any) => {
        modeldoc.close();
        this.event.emit(finderInfo);
      });
    }

  public closemodel()
  {
  }

  get getEmail(){
    return this.contactInfo.get('email')
  }

  get getFirstName(){
    return this.contactInfo.get('firstName')
    }

}
