import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { OptionsPet } from 'src/app/Models/PetTypes';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  apiUrl: string = environment.apiUrl;
  constructor(private httpClient: HttpClient) { }


  signUp(singUpForm: FormGroup): Observable<any> {


    let queryParameters = new HttpParams();
    queryParameters = queryParameters.append('first_name',singUpForm.value.firstname)
    queryParameters = queryParameters.append('last_name',singUpForm.value.lastname)
    queryParameters = queryParameters.append('password',singUpForm.value.password)
    queryParameters = queryParameters.append('mobile',singUpForm.value.mobile)
    queryParameters = queryParameters.append('code_phone',singUpForm.value.code_phone)
    queryParameters = queryParameters.append('primary_email',singUpForm.value.email)
    return this.httpClient.request<any>('get', environment.apiUrl+"/v2/email_sign_up?",
    {
        params: queryParameters,
    });
  }


 addPet(id_group:string ,id_user:string, id_language:any, addPetForms: any,observe: any = 'body'): Observable<any>{
  let queryParameters = new HttpParams();
  queryParameters.set('id_user', <any> id_user);
  queryParameters.set('id_group', <any>id_group);
  queryParameters.set("id_language",<any>id_language)
  // queryParameters = queryParameters.append('first_name',addPetForms.value.namePet)
  // queryParameters = queryParameters.append('categorie',addPetForms.value.petCategorie)

  return this.httpClient.request<any>('post', environment.apiUrl+"/v2/add_edit_pet_info",
  {
    params: queryParameters,
    body:addPetForms,
});
 }

 addObject(id_group:string ,id_user:string, id_language:any,sn:any, addObjectForms: any,observe: any = 'body'): Observable<any>{
  let queryParameters = new HttpParams();
  queryParameters =  queryParameters.append('id_user', <any> id_user);
  queryParameters = queryParameters.append('id_group', <any>id_group);
  queryParameters = queryParameters.append("id_language",<any>id_language);
  queryParameters=queryParameters.append("sn",<any>sn);
  return this.httpClient.request<any>('post', environment.apiUrl+"/create_edit_object_tag_v2?",
  {
    params: queryParameters,
    body:addObjectForms,
});
 }





getAllTypeOfPet():Observable<OptionsPet>{
  let id_language =localStorage.getItem("languageId")
  return this.httpClient.request<any>('get',environment.apiUrl + "/v2/tag_types?id_language="+id_language)
}

getAllTypeOfObject():Observable<any>{
  let id_language =localStorage.getItem("languageId")  
  return this.httpClient.request<any>('get',environment.apiUrl + "/v2/object_tag_types?id_language")
}


getAllTypeOfMedicalObject():Observable<any>{
  return this.httpClient.request<any>('get',environment.apiUrl + "/v2/object_mediacal_tag_types")
}




getScanTag(serial_number:any,id_user:any,id_member:any,id_language:any,id_group:any):Observable<any>{
  let queryParameters = new HttpParams();
  queryParameters =queryParameters.append('serial_number', <any> serial_number);
  queryParameters =queryParameters.append('id_group', <any>id_group);
  queryParameters = queryParameters.append("id_language",<any>id_language);
  queryParameters =queryParameters.append("id_user",<any>id_user)
  queryParameters =queryParameters.append("id_member",<any>id_member)
  return this.httpClient.request<any>('get', environment.apiUrl+"/v2/scan_tag",
  {
    params: queryParameters,

});
}

getScanTagObject(serial_number:any,id_user:any,id_member:any,id_language:any,id_group:any):Observable<any>{

  let queryParameters = new HttpParams();
  queryParameters =queryParameters.append('serial_number', <any> serial_number);
  queryParameters =queryParameters.append('id_group', <any>id_group);
  queryParameters = queryParameters.append("id_language",<any>id_language);
  queryParameters =queryParameters.append("id_user",<any>id_user)
  queryParameters =queryParameters.append("id_member",<any>id_member)
  return this.httpClient.request<any>('get', environment.apiUrl+"/scan_tag",
  {
    params: queryParameters,

});


}


}
