import { TranslateService } from '@ngx-translate/core';
import { ParamApplication } from './../../models/ParamApp';

import { BehaviorSubject, Observable, throwError,Subject, forkJoin, of, from } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable, Output, ChangeDetectorRef } from '@angular/core';
import { User } from '../../models/user.model'
import { MedicalInfo } from '../../models/MedicalInformation';
import { Router } from '@angular/router';


@Injectable()

export class AuthService {
  private router: Router;
  private _listnersMedical$ = new Subject<any>();
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public paramApp: Observable<ParamApplication>;
  public paramAppSubject: BehaviorSubject<ParamApplication>;
  public medicalUserSubject: BehaviorSubject<MedicalInfo>;
  public medicalUser: Observable<MedicalInfo>;
  private currentLanguagesSubject: BehaviorSubject<any>;
  public currentLanguages: Observable<any>;
  private headers = new HttpHeaders({"Content-Type": "application/json","Access-Control-Allow-Origin":"*"});
  private options = { headers: this.headers };
  constructor(private http: HttpClient, private translateService: TranslateService) {

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.medicalUserSubject = new BehaviorSubject<MedicalInfo>(JSON.parse(localStorage.getItem('currentMedicalUser')));
    this.medicalUser = this.medicalUserSubject.asObservable();
    this.currentLanguagesSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentLanguage')));
    this.currentLanguages = this.currentLanguagesSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  public get medicalUserValue(): MedicalInfo {

    return this.medicalUserSubject.value;

  }

  public get paramAppValue(): ParamApplication {
    return this.paramAppSubject.value;
  }

  public get currentLanguagesValue(): any {
    return this.currentLanguagesSubject.value;
  }

  listenMedicalInfo(): Observable<any> {
    return this._listnersMedical$.asObservable();
  }

  pushMedicalInfo(filterBy: any) {
    this._listnersMedical$.next(filterBy);
  }

  // login(SerialNumberInput: string, idDevice: string, userId?: string) {
  //   let idUser='';
  //   if (userId !== null)
  //   idUser = '&id_user='+userId
  // console.log("idUser",idUser);

  //   return this.http.get<any>(`${environment.apiUrl}/finder_scan_tag?sn=` + SerialNumberInput.replace('#','')+'&id_device='+idDevice+ idUser)
  //     .pipe(map(user => {
  //       if (user && !user?.error) {
  //         console.log("suer", user);
  //         localStorage.setItem('currentUser', JSON.stringify(user));
  //         console.log("currentUser", user);
  //         this.currentUserSubject.next(user);
  //       }
  //       return user;
  //     }));
  // }

  login(SerialNumberInput: string, idDevice: string, userId?: string) {
    let idUser = '';
    if (userId !== null) idUser = '&id_user=' + userId;

    return this.http.get<any>(`${environment.apiUrl}/finder_scan_tag?sn=${SerialNumberInput.replace('#', '')}&id_device=${idDevice}${idUser}`)
        .pipe(
            switchMap(user => {
                if (user && !user?.error) {
                    console.log("suer", user);

                    // Handle translation if tag_custum_message exists
                    if (user.tag_info?.tag_info?.tag_custum_message) {
                        return this.handleTranslation(user); // Return the translation observable
                    } else {
                        // No translation needed, proceed to save user
                        this.saveUser(user);
                        return of(user); // Wrap user in an observable
                    }
                }
                return of(user); // Return user even if there's an error property
            })
        );
}

handleTranslation(user: any) {
    return this.translationMessage(user.tag_info.tag_info.tag_custum_message).pipe(
        map((res: any) => {
            if (res?.translation?.translations?.[0]?.translation) {
                user.tag_info.tag_info.tag_custum_message = res.translation.translations[0].translation;
                console.log("Translated user custom message:", res.translation.translations[0].translation);
            } else {
                console.log("No translation found for user custom message:", user.tag_info.tag_info.tag_custum_message);
            }

            this.saveUser(user); // Save user after translation
            return user; // Return updated user
        }),
        catchError(error => {
            console.log("Error translating user custom message:", error);
            this.saveUser(user); // Save user even if translation fails
            return of(user); // Return user in case of an error
        })
    );
}

saveUser(user: any) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    console.log("currentUser", user);
    this.currentUserSubject.next(user);
}





  sendsmsmail(sn:string, choice?: string){
    let isMedical = 0;
    if(localStorage.getItem('currentChatbot') === 'medical')
    isMedical = 1;
    else
    isMedical = 0;

    let choiceChat = "";
    if(choice) choiceChat = "&choice="+choice;

    return this.http.get<any>(`${environment.apiUrl}/send_sms_mail_to_allowed_emergency?is_medical=`+ isMedical+"&sn=" + sn+choiceChat)
    .pipe(map(data => {
      return data;
    }));
  }

  // Getemergency(SerialNumberInput: string, pincode:string) {
  //   return this.http.get<any>(`${environment.apiUrl}/get_medical_info_when_object_scaned?sn=`+SerialNumberInput +"&pincode="+pincode)
  //     .pipe(map(
  //       emergencydata => {
  //       localStorage.setItem('currentMedicalUser', JSON.stringify(emergencydata));
  //       this.medicalUserSubject.next(emergencydata);
  //       this.medicalUser = this.medicalUserSubject.asObservable();
  //       this.pushMedicalInfo( this.medicalUserSubject.value);
  //       return emergencydata;

  //     }),
  //     catchError(err => {
  //       const error = err.error.message || err.statusText;
  //       if(err.status === 500)
  //       {
  //          //window.alert(error)
  //       }

  //       return error;
  //     })
  //    );
  //   }





  // Getemergency(SerialNumberInput: string, pincode: string) {
  //   return this.http.get<any>(`${environment.apiUrl}/get_medical_info_when_object_scaned?sn=` + SerialNumberInput + "&pincode=" + pincode)
  //     .pipe(
  //       map(emergencydata => {
  //         console.log("entering emergencydata", emergencydata); // Log to check the structure of emergencydata
  
  //         // Check if medical_record and crtitcal_info are present
  //         if (emergencydata.medical_record && emergencydata.medical_record.crtitcal_info) {
  //           const translatePromises = emergencydata.medical_record.crtitcal_info.map((item: any) => {
  //             console.log("entering translatePromises for crtitcal_info item:", item); // Log the crtitcal_info item
  
  //             // Translate description and label fields if they exist
  //             const fieldsToTranslate = ['description', 'label'];
  
  //             return Promise.all(fieldsToTranslate.map(field => {
  //               if (typeof item[field] === 'string') {
  //                 console.log(`Translating ${field} for item:`, item[field]);
  //                 return this.translationMessage(item[field]).toPromise().then((res: any) => {
  //                   if (res?.translation?.translations?.length > 0 && res?.translation?.translations[0]?.translation) {
  //                     item[field] = res.translation.translations[0].translation;
  //                     console.log(`Translated ${field}:`, item[field]);
  //                   } else {
  //                     console.log(`No translation found for ${field} in item:`, item);
  //                   }
  //                 }).catch(error => {
  //                   console.log(`Error translating ${field}:`, error); // Log any translation errors
  //                 });
  //               }
  //               return Promise.resolve(); // Skip if the value is not a string
  //             }));
  //           });
  
  //           // Wait for all translations to finish
  //           return Promise.all(translatePromises).then(() => {
  //             // Translate custom message if it exists
  //             if (emergencydata?.user_general_info?.sub_users[0]?.user_general_info?.custum_message && typeof emergencydata?.user_general_info?.sub_users[0].user_general_info?.custum_message=== 'string') {
  //               return this.translationMessage(emergencydata.user_general_info.sub_users[0].user_general_info.custum_message).toPromise().then((res: any) => {
  //                 if (res?.translation?.translations?.length > 0 && res?.translation?.translations[0]?.translation) {
  //                   emergencydata.user_general_info.sub_users[0].user_general_info.custum_message = res.translation.translations[0].translation;
  //                   console.log("Translated custom message:", emergencydata.user_general_info.sub_users[0].user_general_info.custum_message);
  //                 } else {
  //                   console.log("No translation found for custom message:", emergencydata.user_general_info.sub_users[0].user_general_info.custum_message);
  //                 }
  //               }).catch(error => {
  //                 console.log("Error translating custom message:", error);
  //               });
  //             }
  //             return Promise.resolve(); // Skip if there's no custom message or it's not a string
  //           }).then(() => {
  //             // Store the translated emergency data in local storage and update the subject
  //             localStorage.setItem('currentMedicalUser', JSON.stringify(emergencydata));
  //             this.medicalUserSubject.next(emergencydata);
  //             this.medicalUser = this.medicalUserSubject.asObservable();
  //             this.pushMedicalInfo(this.medicalUserSubject.value);
  //             return emergencydata; // Return the translated data
  //           });
  //         } else {
  //           console.log("No crtitcal_info data found to translate.");
  //           return emergencydata; // If crtitcal_info is not present, return as is
  //         }
  //       }),
  //       catchError(err => {
  //         const error = err.error.message || err.statusText;
  //         if (err.status === 500) {
  //           // window.alert(error)
  //         }
  //         return error;
  //       })
  //     );
  // }
  


  // Getemergency(SerialNumberInput: string, pincode: string) {
  //   return this.http.get<any>(`${environment.apiUrl}/get_medical_info_when_object_scaned?sn=${SerialNumberInput}&pincode=${pincode}`)
  //     .pipe(
  //       switchMap(emergencydata => {
  //         console.log("entering emergencydata", emergencydata);
  
  //         if (emergencydata.medical_record && emergencydata.medical_record.crtitcal_info) {
  //           const translatePromises = emergencydata.medical_record.crtitcal_info.map((item: any) => {
  //             const fieldsToTranslate = ['description', 'label'];
  //             return Promise.all(fieldsToTranslate.map(field => {
  //               if (typeof item[field] === 'string') {
  //                 return this.translationMessage(item[field]).toPromise()
  //                   .then((res: any) => {
  //                     if (res?.translation?.translations?.[0]?.translation) {
  //                       item[field] = res.translation.translations[0].translation;
  //                       console.log("translated text : " , item[field]);
  //                     }
  //                   })
  //                   .catch(error => console.log(`Error translating ${field}:`, error));
  //               }
  //               return Promise.resolve();
  //             }));
  //           });
  
  //           return from(Promise.all(translatePromises)).pipe(
  //             switchMap(() => {
  //               if (emergencydata?.user_general_info?.sub_users[0]?.user_general_info?.custum_message) {
  //                 return from(this.translationMessage(emergencydata.user_general_info.sub_users[0].user_general_info.custum_message).toPromise())
  //                   .pipe(
  //                     map((res: any) => {
  //                       if (res?.translation?.translations?.[0]?.translation) {
  //                         emergencydata.user_general_info.sub_users[0].user_general_info.custum_message = res.translation.translations[0].translation;
  //                       }
  //                       return emergencydata;
  //                     })
  //                   );
  //               }
  //               return of(emergencydata);
  //             })
  //           );
  //         } else {
  //           return of(emergencydata);
  //         }
  //       }),
  //       tap(emergencydata => {
  //         localStorage.setItem('currentMedicalUser', JSON.stringify(emergencydata));
  //         this.medicalUserSubject.next(emergencydata);
  //         this.medicalUser = this.medicalUserSubject.asObservable();
  //         this.pushMedicalInfo(this.medicalUserSubject.value);
  //       }),
  //       catchError(err => {
  //         const error = err.error.message || err.statusText;
  //         return throwError(() => new Error(error));
  //       })
  //     );
  // }



// Start of Medical Transaltion Section
  Getemergency(SerialNumberInput: string, pincode: string) {
    return this.http.get<any>(`${environment.apiUrl}/get_medical_info_when_object_scaned?sn=${SerialNumberInput}&pincode=${pincode}`)
      .pipe(
        switchMap(emergencydata => {
  
          // Translate critical info
          if (emergencydata.medical_record?.crtitcal_info) {
            return from(this.translateCriticalInfo(emergencydata.medical_record.crtitcal_info)).pipe(
              map(() => emergencydata) // Return translated data
            );
          }
          return of(emergencydata); 
        }),
  
        switchMap(emergencydata => {
          if (emergencydata?.medical_record?.medical_diseaces) {
            return from(this.translateMedicalDiseases(emergencydata.medical_record.medical_diseaces)).pipe(
              map(() => emergencydata) 
            );
          }
          return of(emergencydata); 
        }),
  
        switchMap(emergencydata => {
          if (emergencydata?.medical_record?.immunization) {
            return from(this.translateImmunizations(emergencydata.medical_record.immunization)).pipe(
              map(() => emergencydata) 
            );
          }
          return of(emergencydata); 
        }),
        
        switchMap(emergencydata => {
          if (emergencydata?.medical_record?.other_medical_record_info) {
            return from(this.translateOtherDisorders(emergencydata?.medical_record?.other_medical_record_info)).pipe(
              map(() => emergencydata) // Return translated data
            );
          }
          return of(emergencydata); 
        }), 
        
        switchMap(emergencydata => {
          if (emergencydata?.medical_record?.blood_info?.diabates) {
            return from(this.translateBloodDisorders(emergencydata?.medical_record?.blood_info?.diabates)).pipe(
              map(() => emergencydata) // Return translated data
            );
          }
          return of(emergencydata); 
        }),
  
        tap(emergencydata => {
          localStorage.setItem('currentMedicalUser', JSON.stringify(emergencydata));
          this.medicalUserSubject.next(emergencydata);
          this.medicalUser = this.medicalUserSubject.asObservable();
          this.pushMedicalInfo(this.medicalUserSubject.value);
        }),
  
        catchError(err => {
          const error = err.error.message || err.statusText;
          return throwError(() => new Error(error));
        })
      );
  }
  


// Function to translate critical_info section
translateCriticalInfo(criticalInfo: any): Promise<any[]> {
  if (criticalInfo && criticalInfo.length > 0) {
    const translatePromises = criticalInfo.map((item: any) => {
      const fieldsToTranslate = ['description', 'label'];
      return Promise.all(fieldsToTranslate.map(field => {
        if (typeof item[field] === 'string') {
          return this.translationMessage(item[field]).toPromise()
            .then((res: any) => {
              if (res?.translation?.translations?.[0]?.translation) {
                item[field] = res.translation.translations[0].translation;
              }
            })
            .catch(error => console.log(`Error translating ${field}:`, error));
        }
        return Promise.resolve();
      }));
    });

    return Promise.all(translatePromises);
  }
  return Promise.resolve([]); 
}

// Function to translate medical_diseases section
translateMedicalDiseases(medicalDiseases: any): Promise<any[]> {
  if (medicalDiseases && Object.keys(medicalDiseases).length > 0) {
    const diseaseKeys = Object.keys(medicalDiseases);
    const diseasePromises = diseaseKeys.map(diseaseKey => {
      const disease = medicalDiseases[diseaseKey];
      const fieldsToTranslate = ['name', 'label']; 

      // Translate 'name' and 'label' for the disease itself
      const translateDiseaseFields = fieldsToTranslate.map(field => {
        if (disease[field] && typeof disease[field] === 'string') {
          return this.translationMessage(disease[field]).toPromise()
            .then((res: any) => {
              if (res?.translation?.translations?.[0]?.translation) {
                disease[field] = res.translation.translations[0].translation;
              }
            })
            .catch(error => console.log(`Error translating disease ${field}:`, error));
        }
        return Promise.resolve(); 
      });

      // Process blocks within each disease
      if (disease.blocks && disease.blocks.length > 0) {
        const blockPromises = disease.blocks.map((block: any) => {
          const blockFieldsToTranslate = ['description', 'label']; 

          // Translate both 'description' and 'label' for each block
          return Promise.all(blockFieldsToTranslate.map(field => {
            if (block[field] && typeof block[field] === 'string') {
              return this.translationMessage(block[field]).toPromise()
                .then((res: any) => {
                  if (res?.translation?.translations?.[0]?.translation) {
                    block[field] = res.translation.translations[0].translation;
                  }
                })
                .catch(error => console.log(`Error translating block ${field}:`, error));
            }
            return Promise.resolve(); 
          }));
        });

        return Promise.all(blockPromises).then(() => Promise.all(translateDiseaseFields)); // Wait for both block and disease translations
      }

      return Promise.all(translateDiseaseFields); 
    });

    
    return Promise.all(diseasePromises);
  }

  
  return Promise.resolve([]);
}


// Function to translate immunizations section
translateImmunizations(immunizations: any): Promise<any[]> {
  if (immunizations && immunizations.blocks && immunizations.blocks.length > 0) {
    const blockPromises = immunizations.blocks.map((block: any) => {
      const fieldsToTranslate = ['description', 'label']; 

      return Promise.all(fieldsToTranslate.map(field => {
        if (block[field] && typeof block[field] === 'string') {
          return this.translationMessage(block[field]).toPromise()
            .then((res: any) => {
              if (res?.translation?.translations?.[0]?.translation) {
                block[field] = res.translation.translations[0].translation;
              }
            })
            .catch(error => console.log(`Error translating immunization block ${field}:`, error));
        }
        return Promise.resolve(); 
      }));
    });

    return Promise.all(blockPromises);
  }
  return Promise.resolve([]); 
}



translateOtherDisorders(otherDisorders: any[]): Promise<any[]> {
  const translatePromises = otherDisorders.map((item: any) => {
    const fieldsToTranslate = ['description', 'label'];

    return Promise.all(fieldsToTranslate.map(field => {
      if (typeof item[field] === 'string') {
        return this.translationMessage(item[field]).toPromise()
          .then((res: any) => {
            if (res?.translation?.translations?.[0]?.translation) {
              item[field] = res.translation.translations[0].translation;
            }
          })
          .catch(error => console.log(`Error translating ${field}:`, error));
      }
      return Promise.resolve(); 
    }));
  });

  // Ensure we always return a promise
  return Promise.all(translatePromises);
}



translateBloodDisorders(bloodDisorders: any[]): Promise<any[]> {
  const translatePromises = bloodDisorders.map((item: any) => {
    const fieldsToTranslate = ['diabete_description', 'diabete_label'];

    return Promise.all(fieldsToTranslate.map(field => {
      if (typeof item[field] === 'string') {
        return this.translationMessage(item[field]).toPromise()
          .then((res: any) => {
            if (res?.translation?.translations?.[0]?.translation) {
              item[field] = res.translation.translations[0].translation;
            }
          })
          .catch(error => console.log(`Error translating ${field}:`, error));
      }
      return Promise.resolve(); 
    }));
  });

  // Ensure we always return a promise
  return Promise.all(translatePromises);
}

// End of Medical Transaltion Section

 ApprouveEmereg(SerialNumber:string)
  {
    return this.http.get<any>(`${environment.apiUrl}/validate_generate_pin_code?sn=` + SerialNumber)
  }
  getTranslationId(lang: string): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}/get_id_language_from_abr?abr=${lang}`)
    .pipe(map(data => {
      return data;
    }));
    }

  translationMessage(msg: string): Observable<any> {
      // let username = 'a88d44f4-92a8-44ff-a084-9f99c3811905';
      //  let password =
      //      'sZu0ZIX1QOKdO6QLxKsxJUJGJpAJF2vOS1AZu79VH8zOrFhf8n47pi7vDa5nTICg';

      let headers= new HttpHeaders();
      headers = headers.append('apiKey', environment.translateApiKey);

      // ({ 'Content-Type': 'application/json',
      const browserlang = this.translateService.getBrowserLang();

      var userLang = browserlang !== null ? browserlang : 'fr'
      // 'Authorization':"Basic " + btoa(username + ':' + password)}) ;
      var raw = {
        "text":msg,
        "translate_to":userLang
      };

      return this.http.request<any>('post', `${environment.apiUrl}/chatbot_translation`,
      {
        headers: headers,
        body:raw,
      });

      }

    public getIPAddress()
    {
          return this.http.get("http://api.ipify.org/?format=json");
    }

    public blocip(ip : string){
      return this.http.get(`${environment.apiUrl}/block_ip?ip=${ip}`)
    }

    logout() {
      localStorage.removeItem('currentUser');
      this.currentUserSubject.next(null);
      this.router.navigate(['']);
    }

    getParamApp(){
      return this.http.get<any>(`${environment.apiUrl}/get_param_app` )
      .pipe(map(
        data => {
        this.paramAppSubject= new BehaviorSubject<ParamApplication>(data);
        this.paramApp = this.paramAppSubject.asObservable();
        return data;

      }),
     );
    }

    getViewUser(idUser: string ) {
      let queryParameters = new HttpParams();
      // queryParameters = queryParameters.append('id_user', idUser)
      return this.http.get(`${environment.apiUrl}/view_user?id_user=` + idUser+'&id_language=38');
    }

}
